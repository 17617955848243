<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item label="账号类型">
        <a-row :gutter="10">
          <a-col :span="8">
            <div class="role-item" :class="stepData.role_id == 1 ? 'active' : ''" @click="handleChooseRole(1)">
              <div class="name">需求方</div>
              <div class="desc">要发项目</div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="role-item" :class="stepData.role_id == 2 ? 'active' : ''" @click="handleChooseRole(2)">
              <div class="name">服务商</div>
              <div class="desc">接项目</div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="role-item" :class="stepData.role_id == 4 ? 'active' : ''" @click="handleChooseRole(4)">
              <div class="name">个体</div>
              <div class="desc">要做项目</div>
            </div>
          </a-col>
        </a-row>
        <a-input
          type="hidden"
          v-decorator="['role_id', {initialValue: stepData.role_id, rules: [{required: true, message: '请选择账号类型'}]}]"
        />
        <!-- <a-select v-decorator="['role_id', {initialValue: stepData.role_id, rules: [{required: true, message: '请输入登录密码'}]}]" :allowClear="true">
          <a-select-option v-if="userInfo.role_id == 0 || userInfo.role_id == undefined" :value="1">公司</a-select-option>
          <a-select-option v-if="userInfo.role_id == 0 || userInfo.role_id == undefined" :value="2">服务商</a-select-option>
          <a-select-option :value="4">分包商</a-select-option>
        </a-select> -->
      </a-form-item>
      <a-form-item label="登录账号">
        <a-input
          type="text"
          v-decorator="['username', {initialValue: stepData.username, rules: [{required: true, message: '请输入登录账号'}]}]"
        />
      </a-form-item>
      <a-form-item label="登录密码">
        <a-input-password
          v-decorator="['password', {initialValue: stepData.password, rules: [{required: true, message: '请输入登录密码'}]}]"
        />
      </a-form-item>
      <a-form-item label="验证码">
        <a-input
          v-decorator="['code', {initialValue: stepData.code, rules: [{required: true, message: '请输入登录密码'}]}]"
        >
        <img slot="addonAfter" :src="codeUrl" alt="code" @click="resetCode" />
        </a-input>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Step1',
  data () {
    return {
      stepData: {
        username: '',
        password: '',
        role_id: '',
        code: ''
      },
      codeUrl: '/admin/code',
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.stepData = {
      username: this.formData.username || '',
      password: this.formData.password || '',
      role_id: this.formData.role_id || '',
      code: this.formData.code || ''
    }
  },
  methods: {
    handleChooseRole(role_id) {
      this.stepData.role_id = role_id
    },
    resetCode() {
      this.codeUrl = `admin/code?${Math.random()}`
    },
    nextStep () {
      
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('nextStep', values)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.role-item {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #8d8d8d;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &.active {
    border-color: #70a0f8;
    &::before {
      content: ' ';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 40px solid #448ef7;
      border-left: 40px solid transparent;
    }
    &::after {
      content: '✓';
      position: absolute;
      font-size: 20px;
      width: 20px;
      line-height: 30px;
      text-align: center;
      right: 0;
      bottom: 0;
      color: #fff;
    }
  }
  .name {
    line-height: 24px;
    font-size: 18px;
    font-weight: bold;
  }
  .desc {
    font-size: 14px;
    color: #666;
    line-height: 20px;
  }
}

</style>
